import dashService from '../../api/dash'

const state = {
  securedCount: 0,
  unSecuredCount: 0,
  securedMotorVehicleCount: 0,
  securedTitleDeedCount: 0,
  aboutUsCount: 0,
  inquiryCount: 0
}

const mutations = {
  setSecuredCount (state, count) {
    state.securedCount = count
  },
  setUnSecuredCount (state, count) {
    state.unSecuredCount = count
  },
  setSecuredMotorVehicleCount (state, count) {
    state.securedMotorVehicleCount = count
  },
  setSecuredTitleDeedCount (state, count) {
    state.securedTitleDeedCount = count
  },
  setAboutUsCount (state, count) {
    state.aboutUsCount = count
  },
  setInquiryCount (state, count) {
    state.inquiryCount = count
  }
}

const actions = {
  getSecuredCount ({ commit }) {
    dashService.getSecuredCount().then(response => {
      commit('setSecuredCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getUnSecuredCount ({ commit }) {
    dashService.getUnSecuredCount().then(response => {
      commit('setUnSecuredCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getSecuredMotorVehicleCount ({ commit }) {
    dashService.getSecuredMotorVehicleCount().then(response => {
      commit('setSecuredMotorVehicleCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getSecuredTitleDeedCount ({ commit }) {
    dashService.getSecuredTitleDeedCount().then(response => {
      commit('setSecuredTitleDeedCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getAboutUsCount ({ commit }) {
    dashService.getAboutUsCount().then(response => {
      commit('setAboutUsCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getInquiryCount ({ commit }) {
    dashService.getInquiryCount().then(response => {
      commit('setInquiryCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getSecuredMotorVehicleSessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dashService.getSecuredMotorVehicleSessions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getSecuredTitleDeedSessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dashService.getSecuredTitleDeedSessions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUnSecuredSessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dashService.getUnSecuredSessions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAboutUsSessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dashService.getAboutUsSessions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInquirySessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dashService.getInquirySessions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
