import http from '../http'

export default {
  getMenus () {
    return http.get('/menu')
  },
  getMenuChoices (id) {
    return http.get(`/menu/${id}/choices`)
  },
  createMenuChoice (choice) {
    return http.post('/menu/choice', choice)
  },
  deleteMenuChoice (choice) {
    return http.post('/menu/choice/delete', choice)
  },
  editMenuMedia (request) {
    return http.post('/menu/media', request)
  },
  editMenu (request) {
    return http.post('/menu', request)
  }
}
