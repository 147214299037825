import Vue from 'vue'
import VueRouter from 'vue-router'
import Dash from '../views/Dash.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/user_signup/:token',
    name: 'UserSignup',
    component: () => import(/* webpackChunkName: "home" */ '../views/UserSignup.vue')
  },
  {
    path: '/forgot-pass',
    name: 'ForgotPassRequest',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/password_reset/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue')
  },
  {
    path: '/',
    name: 'Dash',
    component: Dash,
    children: [
      {
        path: '/admin',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: '/menu',
        name: 'Menu',
        component: () => import(/* webpackChunkName: "home" */ '../views/Menu.vue')
      },
      {
        path: '/menu/choices/:id',
        name: 'MenuChoice',
        component: () => import(/* webpackChunkName: "home" */ '../views/MenuChoices.vue')
      },
      {
        path: '/session',
        name: 'Sessions',
        component: () => import(/* webpackChunkName: "home" */ '../views/Session.vue')
      },
      {
        path: '/session/:id/messages',
        name: 'SessionMessages',
        component: () => import(/* webpackChunkName: "home" */ '../views/Messages.vue')
      },
      {
        path: '/permission',
        name: 'Permission',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Permission.vue')
      },
      {
        path: '/role',
        name: 'Role',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Role.vue')
      },
      {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/User.vue')
      },
      {
        path: '/contacts',
        name: 'Contacts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Contacts.vue')
      },
      {
        path: '/secured_sessions',
        name: 'SecuredDash',
        component: () => import(/* webpackChunkName: "admin" */ '../views/SecuredDash.vue')
      },
      {
        path: '/secured_sessions/motor',
        name: 'SecuredMotor',
        component: () => import(/* webpackChunkName: "admin" */ '../views/SecuredMotorSessions.vue')
      },
      {
        path: '/secured_sessions/title',
        name: 'SecuredTitle',
        component: () => import(/* webpackChunkName: "admin" */ '../views/SecuredTitleSessions.vue')
      },
      {
        path: '/unsecured_sessions',
        name: 'UnSecuredSessions',
        component: () => import(/* webpackChunkName: "admin" */ '../views/UnSecuredSessions.vue')
      },
      {
        path: '/aboutus_sessions',
        name: 'AboutUsSessions',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AboutUsSessions.vue')
      },
      {
        path: '/inquiry_sessions',
        name: 'InquirySessions',
        component: () => import(/* webpackChunkName: "admin" */ '../views/InquirySessions.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = window.localStorage.getItem('token') !== 'null'
  console.log(window.localStorage.getItem('token'))
  console.log(isAuthenticated)
  console.log(typeof window.localStorage.getItem('token'))
  if (to.name !== 'Login' && !isAuthenticated) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

if (window.localStorage) {
  var localUserString = window.localStorage.getItem('user') || 'null'
  var localUser = JSON.parse(localUserString)

  if (localUser && store.state.session !== localUser) {
    store.dispatch('session/setUser', localUser)
    store.dispatch('session/setToken', window.localStorage.getItem('token'))
  }
}

export default router
