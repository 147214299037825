import botSessionService from '../../api/botsession'
const state = {
  sessions: [],
  sessionCount: 0,
  messages: []
}

const mutations = {
  setSessions (state, sessions) {
    state.sessions = sessions
  },
  setMediaSessions (state, sessions) {
    state.mediaSessions = sessions
  },
  setSessionCount (state, count) {
    state.sessionCount = count
  },
  setSessionMessages (state, messages) {
    state.messages = messages
  }
}

const actions = {
  getSessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      botSessionService.getSessions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getSessionCount ({ commit }) {
    botSessionService.getSessionCount().then(response => {
      commit('setSessionCount', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getSessionMessages ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      botSessionService.getSessionMessages(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  exportSessions ({ commit }) {
    return new Promise((resolve, reject) => {
      botSessionService.exportSessions().then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
