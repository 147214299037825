import Vue from 'vue'
import Vuex from 'vuex'
import session from './modules/session'
import alert from './modules/alert'
import menu from './modules/menu'
import botsession from './modules/botsession'
import user from './modules/user'
import role from './modules/role'
import permission from './modules/permission'
import dash from './modules/dash'
import contacts from './modules/contacts'
import giftcards from './modules/giftcards'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    session,
    menu,
    botsession,
    user,
    role,
    permission,
    dash,
    contacts,
    giftcards
  }
})
