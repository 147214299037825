import http from '../http'

export default {
  getContacts (payload) {
    return http.get(`/chats?per_page=${payload.perPage}&page=${payload.page}`)
  },
  exportSessions () {
    return http.get('/chats/export', { responseType: 'blob' })
  }
}
