import http from '../http'

export default {
  getPurchases (payload) {
    return http.get(`/purchase?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getRedemptions (payload) {
    return http.get(`/transaction?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getBalanceCheckRequests (payload) {
    return http.get(`transaction/balance_check?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getWallets (payload) {
    return http.get(`/wallet?per_page=${payload.perPage}&page=${payload.page}`)
  }
}
