import menuService from '../../api/menu'
const state = {
  menus: [],
  menuchoices: []
}

const mutations = {
  setMenus (state, menus) {
    state.menus = menus
  },
  setMenuChoices (state, menuchoices) {
    state.menuchoices = menuchoices
  }
}

const actions = {
  getMenus ({ commit }) {
    menuService.getMenus().then(response => {
      commit('setMenus', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  getMenuChoices ({ commit }, id) {
    menuService.getMenuChoices(id).then(response => {
      commit('setMenuChoices', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  addMenuChoice ({ commit }, request) {
    commit('')
    return new Promise((resolve, reject) => {
      menuService.createMenuChoice(request).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteMenuChoice ({ commit }, request) {
    commit('')
    return new Promise((resolve, reject) => {
      menuService.deleteMenuChoice(request).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  editMenuMedia ({ commit }, request) {
    return new Promise((resolve, reject) => {
      menuService.editMenuMedia(request).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  editMenu ({ commit }, request) {
    return new Promise((resolve, reject) => {
      menuService.editMenu(request).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
