import http from '../http'

export default {
  getSessions (payload) {
    return http.get(`/session?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getSessionMessages (payload) {
    return http.get(`/session/${payload.sessionId}/messages?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getSessionCount () {
    return http.get('/session/count')
  },
  exportSessions () {
    return http.get('/session/export', { responseType: 'blob' })
  }
}
