<template>
<div>
  <v-app-bar color="#FFFFFF" dark elevation="1" app>
  <v-app-bar-nav-icon @click="drawer = true" color="black"></v-app-bar-nav-icon>
  <v-toolbar-title>
  </v-toolbar-title>
  <v-spacer></v-spacer>
  <v-menu
    left
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="accent">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="logoutDialog = true"
      >
        <v-list-item-title>Log out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
 </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    app
    :permanent="$vuetify.breakpoint.mdAndUp"
    dense
  >
    <v-list class="mt-0">
      <v-img class="justify-center ma-1" contain max-width="180" max-height="80" src="@/assets/logo.png"></v-img>
      <!-- <v-list-item class="px-2">
        <v-list-item-avatar width="100" height="100">
          <v-img  src="https://picsum.photos/id/11/500/300"></v-img>
        </v-list-item-avatar>
      </v-list-item> -->
      <v-list-item to="/admin">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/menu" v-if="can('CAN_VIEW_MESSAGE_SETTING')">
        <v-list-item-icon>
          <v-icon>mdi-dns-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Message Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/contacts" v-if="can('CAN_VIEW_CONTACT') || can('CAN_EXPORT_CONTACT')">
        <v-list-item-icon>
          <v-icon>mdi-contacts</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Contacts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/project" v-if="can('CAN_CREATE_PRODUCT') || can('CAN_VIEW_PRODUCT') || can('CAN_UPDATE_PRODUCT')">
        <v-list-item-icon>
          <v-icon>yard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Projects</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/session" v-if="can('CAN_VIEW_SESSION')">
        <v-list-item-icon>
          <v-icon>mdi-table-settings</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Sessions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
            <v-list-group prepend-icon="mdi-cog-outline" v-if="can('CAN_VIEW_USER') || can('CAN_CREATE_USER') || can('CAN_UPDATE_USER') || can('CAN_VIEW_ROLE') || can('CAN_CREATE_ROLE') || can('CAN_UPDATE_ROLE') || can('CAN_VIEW_PERMISSION') || can('CAN_CREATE_PERMISSION') || can('CAN_UPDATE_PERMISSION')">
        <v-list-item to="/user" class="ml-4" v-if="can('CAN_VIEW_USER') || can('CAN_CREATE_USER') || can('CAN_UPDATE_USER')">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-slot:activator v-if="can('CAN_VIEW_ROLE') || can('CAN_CREATE_ROLE') || can('CAN_UPDATE_ROLE') || can('CAN_VIEW_PERMISSION') || can('CAN_CREATE_PERMISSION') || can('CAN_UPDATE_PERMISSION')">
          <v-list-item-content>
            <v-list-item-title>Other Settings</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-group :value="true" no-action sub-group v-if="can('CAN_VIEW_ROLE') || can('CAN_CREATE_ROLE') || can('CAN_UPDATE_ROLE') || can('CAN_VIEW_PERMISSION') || can('CAN_CREATE_PERMISSION') || can('CAN_UPDATE_PERMISSION')">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Access Control</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/role" v-if="can('CAN_VIEW_ROLE') || can('CAN_CREATE_ROLE') || can('CAN_UPDATE_ROLE')">
            <v-list-item-title>Roles</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link to="/permission" v-if="can('CAN_VIEW_PERMISSION') || can('CAN_CREATE_PERMISSION') || can('CAN_UPDATE_PERMISSION')">
            <v-list-item-title>Permissions</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
  <v-dialog v-model="logoutDialog" persistent max-width="600">
  <v-card>
    <v-card-title class="headline">Logout</v-card-title>
    <v-card-text>
      <h4 class="mt-2"> Are you sure you want to logout? </h4> </v-card-text>
    <v-card-actions>
      <v-btn color="primary" text @click="logoutDialog = false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="logout">Logout</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'NavMenu',
  data: function () {
    return {
      drawer: null,
      item: 0,
      logoutDialog: false
    }
  },
  methods: {
    ...mapActions({ logout: 'session/logout' }),
    logoutUser () {
      this.logout()
    }
  }
}
</script>
<style>
</style>
