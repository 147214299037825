import http from '../http'

export default {
  getSecuredCount () {
    return http.get('/dash/secured/count')
  },
  getUnSecuredCount () {
    return http.get('/dash/unsecured/count')
  },
  getSecuredMotorVehicleCount () {
    return http.get('/dash/secured/motor/count')
  },
  getSecuredTitleDeedCount () {
    return http.get('/dash/secured/title/count')
  },
  getAboutUsCount () {
    return http.get('/dash/about_us/count')
  },
  getInquiryCount () {
    return http.get('/dash/inquiry/count')
  },
  getSecuredMotorVehicleSessions (payload) {
    return http.get(`/dash/secured/motor?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getSecuredTitleDeedSessions (payload) {
    return http.get(`/dash/secured/title?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getUnSecuredSessions (payload) {
    return http.get(`/dash/unsecured?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getAboutUsSessions (payload) {
    return http.get(`/dash/about_us?per_page=${payload.perPage}&page=${payload.page}`)
  },
  getInquirySessions (payload) {
    return http.get(`/dash/inquiry?per_page=${payload.perPage}&page=${payload.page}`)
  }
}
