import giftCardsService from '../../api/giftcards'
const state = {
}

const mutations = {
}

const actions = {
  getPurchases ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      giftCardsService.getPurchases(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getWallets ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      giftCardsService.getWallets(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getRedemptions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      giftCardsService.getRedemptions(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getBalanceCheckRequests ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      giftCardsService.getBalanceCheckRequests(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
